import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

export const ReferencesPageTemplate = ({ title, pageTitle, content, contentComponent, url, references }) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="uk-grid">
      <Helmet title={pageTitle}>
        <meta property="og:title" content={pageTitle}/>
        <meta property="og:url" content={url || '/'}/>
        <link rel="canonical" href={url || '/'} />
        <meta name="twitter:title" content={pageTitle} />
      </Helmet>
      <div className="uk-width-1-1">
        <div className="main uk-width-auto section" id="main">
          <div className="widget Blog" data-version="2" id="Blog1">
            <div className="single_post hfeed" id="single_post">
              <article className="post hentry">
                <div className="post_content">
                  <h1 className="post_title entry-title uk-article-title">
                    {title}
                  </h1>
                  <div className="post_body entry-content uk-margin-large-bottom">
                    <p>
                      <PageContent className="content" content={content} />
                    </p>
                    <div>
                      {references.map((reference, index) => {
                        const key = `reference${index}`;

                        let date = '';
                        if (reference.date) {
                          date = ` (${reference.date})`;
                        }

                        return (
                          <article key={key} className="references">
                            <div className="reference-body">
                              {reference.text}
                              <br/>
                              <cite> – {reference.author}{date}</cite>
                            </div>
                          </article>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

ReferencesPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  references: PropTypes.array,
};

const ReferencesPage = ({ data, location }) => {
  const {
    markdownRemark: post,
    site: {
      siteMetadata: {
        title: siteTitle,
        host
      },
    }
  } = data;

  console.log('ReferencesPage', post.frontmatter.references);
  return (
    <Layout>
      <ReferencesPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        pageTitle={`${post.frontmatter.title} | ${siteTitle}`}
        content={post.html}
        url={`${host}${location.pathname || '/'}`}
        references={post.frontmatter.references}
      />
    </Layout>
  )
};

ReferencesPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ReferencesPage;

export const referencesPageQuery = graphql`
    query ReferencesPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                references {
                    author
                    text
                    date
                }
            }
        }
        site {
            siteMetadata {
                title
                host
            }
        }
    }
`;
